<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :fireFighting="fireFighting"
          :isOld="isOld"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingMasterDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      fireFighting: {
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        sopFireFightingKindCd: null,  // 점검 소방시설 종류
        sopFireFightingKindName: '',  // 점검 소방시설 종류
        manageNo: '',  // 관리번호
        fireFightingName: '',  // 소방설비명
        deptCd: '',  // 관리부서
        processCd: '',  // 관련공정
        sopFireFightinhReplacementCycleCd: '',  // 소방설비 교체 주기
        amount: '',  // 수량
        chmDangerMstId: '',  // 관련 위험물 저장소 일련번호
        location: '',  // 설비위치
        installDate: '',  // 설치일
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        deleteFlag: 'N',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        replacementHistroies: [],
        deleteReplacementHistroies: [],
        histroy: [],
        issueChecks: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingId)
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: '기본정보', component: () => import(`${'./fireFightingInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'history', icon: 'construction', label: '점검이력', component: () => import(`${'./fireFightingHistory.vue'}`)
        })
      }
      return _items;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFighting.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.fireFighting, _result.data)
        },);
      } else {
        // nothing
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>